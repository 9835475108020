import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from './store/root_reducer';
import thunk from 'redux-thunk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

axios.defaults.baseURL='http://www.jcdir.net';


/*middleware 
const logger  = store =>{
    // next get passed in by react and it is a function which allows our action to continue your your
    return next => {
        return action =>{
            //log prior to the action
            console.log('[Middleware] Dispatching ',action); 
            //let it run the action and store the result
            const result = next(action); 
            //log after it returns
            console.log('[Middleware] next state', store.getState()); 
            return result; 

        }
    }
}
*/
const store = createStore(rootReducer, applyMiddleware(thunk));
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

//uncomment if you dont want to use redux
//ReactDOM.render(<App />, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
