import React,{useState,useEffect} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import * as Actions from '../store/attribute_actions';
import AttributesTable from './tables/attribute_table';

// Generate Order Data
function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

 const Attributes = (props)=> {
    useEffect(() =>{
        props.getAttributeList();
    },[]// pass empty array for second argument if you want useEffect only to run once
    );
  const classes = useStyles();
  return (
                        <React.Fragment>
                                <Typography component="h2" variant="h6" color="primary" gutterBottom>Attributes</Typography>
                            <AttributesTable size="small" attributes={props.attList} />
                            <div className={classes.seeMore}>
                                <Link color="primary" href="#" onClick={preventDefault}>
                                See more orders
                                </Link>
                            </div>
                            </React.Fragment>
                );
}
const mapStateToProps = state=>{
  return{
    attList : state.atrReducer.attributes
  };
};

const mapDispatchToProps = dispatch=>{
  return{
    onEditButton :(id) => dispatch(Actions.editAttribute(id)),
    getAttributeList : () => dispatch(Actions.getAllAttributes())  
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(Attributes);

