import React,{useState,useEffect} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteAttributeDialog from '../dialogs/delete_attribute_dialog';
import AddUpdateAttributeDialog from '../dialogs/add_update_attribute_dialog';
import * as AttributeActions from '../../store/attribute_actions';
import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const AttributesTable = (props) =>{
    
    const [currState, setDialog] = React.useState({
        open:false,
        attribute:null,
        hassError:false,
        editorDialogOpen:false,
        snackBarOpen :false,
        updateAttribute:[]
    });
    useEffect(()=>{
        setDialog({
            ...currState,
            hasError:props.hasError,
            updateAttribute:props.updateAttribute
        });
        console.log('use effect ran');
    },[props.hasError,props.updateAttribute]
    );
/**
 * These methods are for handling 
 * @param {*} attribute 
 */
    const openDeleteDialog = (attribute) => {
        setDialog({
            ...currState,
            open:true,
            attribute:attribute
        });
    };

    const closeDeleteDialog = () => {
        setDialog({
            ...currState,
            open:false,
            attribute:null
        });
      }; 
      const handleDelete = () => {
        const {attribute} = currState;
        props.onDeleteAttribute(attribute);
        closeDeleteDialog();
      };   


    /**
     * This method gets invoked when the edit button is clicked on atttribute record
     * @param {} attribute 
     */
    const openAddUpdateDialog =(attribute) =>{
        setDialog({
            ...currState,
            editorDialogOpen:true,
            attribute:attribute
        });
    }

    const closeEditorDialog = () => {
        setDialog({
            ...currState,
            editorDialogOpen:false,
            attribute:null
        });
      }; 
      const handleAddUpdate = (updatedAttribute,event) => {
        const {attribute} = currState;
        event.preventDefault();
        props.onUpdateAttribute(updatedAttribute);
        closeEditorDialog();
      };





 
      const onCloseSnackBar =(event,reason) =>{
            props.clearUpdates();
      }
    return (
        <React.Fragment>
        <Snackbar open={currState.updateAttribute.length >0 || props.hasError}  autoHideDuration={5000} onClose={onCloseSnackBar}>
            <MuiAlert  onClose={onCloseSnackBar} elevation={6} variant="filled" severity={props.hasError==true?"error":"success"} >{props.ctrlMessage}</MuiAlert>
        </Snackbar>    
        <Table size={props.size==null?"small":props.size}>
        <TableHead>
        <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Parent attribute</TableCell>
            <TableCell>Has Sub Attributes</TableCell>
            <TableCell>Last Update</TableCell>
            <TableCell>Update</TableCell>
            <TableCell>Delete</TableCell>
            {/* <TableCell align="right">Sale Amount</TableCell>  keep for align example*/}
        </TableRow>
        </TableHead>
        <TableBody>
        {props.attributes.map(row => (
            <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>{row.attribute!=null?row.attribute.name:null}</TableCell>
            <TableCell>{row.parent!=null?row.parent.name:null}</TableCell>
            <TableCell>{row.hasChild!=null?row.hasChild.toString():null}</TableCell>
            <TableCell><Moment format="MM/DD/YYYY hh:mm" date={row.lastUpdate}/></TableCell>
            <TableCell><EditIcon color={"primary"} onClick={openAddUpdateDialog.bind(this,row) }/></TableCell>
            <TableCell><DeleteIcon color={"secondary"} onClick={openDeleteDialog.bind(this,row) }/></TableCell>
            {/* <TableCell align="right">{row.amount}</TableCell> */}
            </TableRow>
        ))}
        </TableBody>
    </Table>
    <DeleteAttributeDialog open={currState.open} attributeName={currState.attribute!=null?currState.attribute.name:""} 
    onDeleteHandler={handleDelete} closeDialogHandler={closeDeleteDialog}></DeleteAttributeDialog>
    <AddUpdateAttributeDialog 
    open={currState.editorDialogOpen} 
    dialogTitle={"Update Attribute"} 
    onUpdateHandler={handleAddUpdate} 
    closeDialogHandler={closeEditorDialog} 
    targetAttribute={currState.attribute}>

    </AddUpdateAttributeDialog>
    </React.Fragment>
    );
}
const mapStateToProps = state=>{
    return {
        hasError: state.atrReducer.hasError,
        ctrlMessage: state.atrReducer.ctrlMessage,
        updateAttribute: state.atrReducer.updatedAttribute
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onDeleteAttribute:(attribute) => dispatch(AttributeActions.deleteAttribute(attribute)),
        onUpdateAttribute: (attribute) => dispatch(AttributeActions.updateAttribute(attribute)),
        clearUpdates:() => dispatch(AttributeActions.clearUpdates())
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (AttributesTable);