import * as Actions from './attribute_actions';

const initialState={
    attributes:[],
    attributeTypes:[],
    updatedAttribute:[],
    hasError:false
};



const attributeReducer = (state=initialState, action)=>{
    
    switch(action.type)
    {
        case Actions.HANDLE_DELETE_ERROR:
        {
            return  {
                ...state,
                hasError:true,
                ctrlMessage:action.payload
            };

        }
        case Actions.HANDLE_ERROR:
        {
            return  {
                ...state,
                hasError:true
            };

        } 
        case Actions.CLEAR_UPDATES:
        {
            return  {
                ...state,
                updatedAttribute:[],
                hasError:false,
                ctrlMessage:""
            };

        } 
        case Actions.GET_ATTRIBUTE_TYPES:
        {
            return  {
                ...state,
                attributeTypes:action.payload
            };

        } 
        case Actions.GET_ALL_ATTRIBUTES:
        {
            console.log('This is the get all attributes method, payload should be list of attributes from api call');
            return  {
                ...state,
                attributes:action.payload
            }

        } 
        case Actions.ADD_ATTRIBUTE:
        {
            console.log('this is the add attribute in the redux reducer');
            console.log('Attribute in redux '+action.payload);
            return  {
                ...state,
                updatedAttribute:[action.payload],
                attributes:[...state.attributes,action.payload],
                ctrlMessage:"Attribute added successfully"
            }

        } 
        case Actions.UPDATE_ATTRIBUTE:
        {
            const updatedAttribute = action.payload;
            const {attributes} = state;
            attributes.forEach(
                (attribute,ndx)=>{
                    if(updatedAttribute.id ==attribute.id){
                        attributes[ndx] = updatedAttribute;
                        console.log('found updated attribute');
                    }
                }

            );

            return  {
                ...state,
                updatedAttribute:[updatedAttribute],
                attributes:[...attributes],
                ctrlMessage:"Attribute updated successfully"
            }

        }    
        case Actions.EDIT_ATTRIBUTE:
        {
            //if not from pull up item
            //send to form 
            return {
                ...state
            
            };
        }    

        case Actions.DELETE_ATTRIBUTE:{

            console.log("delete attribute dispatched");
             const remaining = state.attributes.filter((attribute)=>
                {
                    return attribute.id != action.payload.id;
                }
             );

            return {
                ...state,
                attributes: remaining,
                updatedAttribute:remaining,
                ctrlMessage:"Attribute deleted successfully"

            
            };
        }

        default: return state;
    }
}

export default attributeReducer ;