import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import * as AttributeActions from '../../store/attribute_actions';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import AttributesTable from '../tables/attribute_table';
import { ThemeProvider } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


class AttributeForm extends React.Component {


    
     constructor(props) {
      super(props);
      this.DEFAULT_ATYPE_ID = 1;      
      this.nameInputRef = React.createRef();
      this.iconInputRef = React.createRef();
      this.descriptionInputRef = React.createRef();
      this.categoryInputRef = React.createRef();
      this.parentInputRef = React.createRef();
      this.hasChildInputRef = React.createRef();

    }
    state={  
      resetFormFunc:()=>this.resetForm(),
      snackbarOpen:false,
      inputErrors:{}
    };
    createElementStyles(){
        return{
            inputElemtWidth:{
                width:500
            }
        };
    }
    //keep these just in case we need to change to controlled components later
    // handleChange = (event) => {
    //   this.setState({...this.state, [event.target.name] : event.target.value});
    // }
  
    validateInputs=()=>{
        this.setState({inputErrors:{}});
        let result = this.validateField(this.nameInputRef,"required","Dumpkopf!! you need to enter a name here");
        result = result && this.validateField(this.descriptionInputRef,"required","Dumpkopf!! How the hell is anyone supposed");
        return result;
    
      }
    validateField=(inputRef, rule, msg)=>{
        if(rule ==="required"){
          if(inputRef.current.value.trim() ===""){
            const inputName = inputRef.current.name;
            this.setState(
              {
                  inputErrors:{...this.state.inputErrors, [inputName]:msg}
              }
            ); 
            return false;         
          }
        }  
        return true;

    }
    handleSubmit = event => 
    {
      event.preventDefault();
      this.props.clearUpdates(); 
      if(!this.validateInputs())
        return; 
      const attribute = this.parseForm();
      if(this.props.id != null)
        attribute.id = this.props.id;
      this.props.handleSubmit(attribute,event);
      this.setState({formRef:this.refs.attributeform, processed:false, hasError:false});
    };
    /**
     * This method will return an attribute object by parsign the inputForm
     */
    parseForm = () =>{
      const attribute = 
      {
        name:this.nameInputRef.current.value,
        description:this.descriptionInputRef.current.value,
        icon:this.iconInputRef.current.value,
        attribute:{
          id:this.categoryInputRef.current.value,
        } ,
        parent:{
          id:this.parentInputRef.current.value
        },
        hasChild:this.hasChildInputRef.current.checked
      };
      if(attribute.parent ==="")
        attribute.parent=null;
      return attribute;

    } 
    
    static getDerivedStateFromProps =(props, state)=>{
        console.log('getDerived state from props called,updated attributes list length is '+props.updatedAttribute.length);
        if(props.updatedAttribute.length > 0 && !state.processed){
          state.resetFormFunc();  
          return {
            snackbarOpen:true,
            processed:true,
            ctrlMessage:"Attribute added successful",
            hasError:false
          }; 
        }
        else if(props.updatedAttribute.length == 0 && props.hasError){
          return {
              snackbarOpen:true,
              processed:false,
              hasError:true,
              ctrlMessage:"There was an error send request to the backend"
          };
        }

    }     
    resetForm = () => {
        
        this.setRefValue(this.nameInputRef,"");
        this.setRefValue(this.descriptionInputRef,"");
        this.setRefValue(this.iconInputRef,"");
        this.setRefValue(this.categoryInputRef,"");
        this.setRefValue(this.parentInputRef,"");
        this.setRefValue(this.hasChildInputRef,"");
    }   
    setRefValue =(ref, value) => {
        if(ref.current!=null){
          ref.current.value =value;
        }
    }
    componentDidMount = ()=>{
      console.log('componentDidMount().......');
      console.log('updated attribute length is '+this.props.updatedAttribute.length );
      console.log('form has been clearned');
      this.props.clearUpdates();

      if(this.props.attributeTypes == null || this.props.attributeTypes.length ==0){
        this.props.loadAttributeTypes();
      if(this.props.attributes == null || this.props.attributes.length == 0 )
        this.props.getAttributeList();
        //alert('executed componentDidMount!!');
      }
    }
    onCloseSnackBbar=(event,reason)=>{
      this.setState({
        ...this.state,
        snackbarOpen:false,
        hasError:false,
        ctrlMessage:null
      })
    }
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState(
        {
          ...this.state,
          snackbarOpen:true,
          hasError:true,
          ctrlMessage:info
        }
      );
    }
    render() {
      const classes = this.createElementStyles();
      const {name, icon, description, attribute, parent, hasChild } = this.props;
      return (<React.Fragment>
              {/* <Snackbar open={this.state.snackbarOpen} autoHideDuration={2000} onClose={this.onCloseSnackBar}>
      <MuiAlert  onClose={this.onCloseSnackBbar} elevation={6} variant="filled" severity={this.state.hasError==true?"error":"success"} >{this.state.ctrlMessage}</MuiAlert>
              </Snackbar> */}
            <form onSubmit={this.handleSubmit} ref="attributeform">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField  name='name' inputRef={this.nameInputRef} onChange={this.handleChange} defaultValue={name} error={this.state.inputErrors.name!= null?true:false}  
                helperText={this.state.inputErrors.name!= null? this.state.inputErrors.name:null}    label="Name"  variant="outlined" style={classes.inputElemtWidth}/>
              </Grid>
              <Grid item xs={12}>
              <TextField  name='icon' inputRef={this.iconInputRef} onChange={this.handleChange}  defaultValue={icon}   label="Icon"  variant="outlined" style={classes.inputElemtWidth}/>
              </Grid>
              <Grid item xs={12}>
                <TextField  name ='description' inputRef={this.descriptionInputRef} onChange={this.handleChange}  defaultValue={description} error={this.state.inputErrors.description!= null?true:false}  
                helperText={this.state.inputErrors.description!= null? this.state.inputErrors.description:null}    label="Description"  variant="outlined" multiline rows={4} style={classes.inputElemtWidth}/>
              </Grid>
              <Grid item sx={12}>
                <FormControl variant="outlined" style={classes.inputElemtWidth}>
                  <InputLabel shrink id="demo-simple-select-placeholder-label-label">Attribute type</InputLabel>
                  <Select name='category' inputRef={this.categoryInputRef} defaultValue={attribute!=null?attribute.id:this.DEFAULT_ATYPE_ID}   labelId="categoryLabelId" id="what" >
                    {
                      this.props.attributeTypes.map(attributeItem =>(
                      <MenuItem key={attributeItem.id} value={attributeItem.id}>{attributeItem.name}</MenuItem> 
                      )

                      )
                    }
                  </Select>
                {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                <FormControl variant="outlined" style={classes.inputElemtWidth}>
                  <InputLabel shrink id="demo-simple-select-placeholder-label-label">Parent Attribute</InputLabel>
                  <Select name='parent' inputRef={this.parentInputRef}  defaultValue={parent!=null?parent.id:""}   labelId="categoryLabelId" id="what"  >
                  <MenuItem value=""><em>None</em></MenuItem>
                    {
                      this.props.attributes.map(attributeItem =>{
                        if(attributeItem.hasChild)
                        return (<MenuItem key={attributeItem.id} value={attributeItem.id}>{attributeItem.name}</MenuItem>);
                      } 
                      )
                    }
                  </Select>
                { <FormHelperText>Leave blank if no parent attribute or unsure</FormHelperText> }
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel control={<Checkbox defaultChecked={hasChild} onChange={this.handleChange} name="hasChild" inputRef={this.hasChildInputRef} />} label="Has sub attributes"/>
                <FormHelperText>Sub attributes are are attributes which would fall under this attribute.  E.g  If this were Restaurant , it would have Chinese,Mexican, Thai etc</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" type="submit" color="primary">{this.props.buttonLabel}</Button>
                </Grid>
              </Grid>  
        </form>
        {
          this.props.updatedAttribute.length >0? 
          <React.Fragment><Typography component="h2" variant="h6" color="primary" gutterBottom>Attributes</Typography>
          <AttributesTable size="small" attributes={this.props.updatedAttribute}/></React.Fragment> :null

        }
        </React.Fragment>
      );
    }
  }
  
  const mapStateToProps = state=> {
    return{
      attributeTypes : state.atrReducer.attributeTypes,
      attributes  :  state.atrReducer.attributes,
      updatedAttribute : state.atrReducer.updatedAttribute,
      hasError: state.atrReducer.hasError
    };
  }
  
  const mapDispatchesToProps = dispatch=>{
      return {
        loadAttributeTypes:  () => dispatch(AttributeActions.getAttributeTypes()),
        getAttributeList : () => dispatch(AttributeActions.getAllAttributes()),
        clearUpdates:() => dispatch(AttributeActions.clearUpdates())
      };
  }

  export default connect(mapStateToProps, mapDispatchesToProps)(AttributeForm);