import React from 'react';
import MainLayout from './layouts/mainlayout';
import {BrowserRouter as Router} from 'react-router-dom';
import './App.css';

//uncomment if you want to use context api instead
//import {Provider } from './context';
function App() {
  return (
    //uncomment if you want to use Context API
    // <Provider>
    //   <Router><MainLayout></MainLayout></Router>
    // </Provider>
    <Router><MainLayout></MainLayout></Router>
  );
}

export default App;
