
import axios  from 'axios';

//resources
export const GET_RESOURCES ='GET_RESOURCES';
export const ADD_RESOURCE='ADD_RESOURCE';
export const UPDATE_RESOURCE='UPDATE_RESOURCE';
export const DELETE_RESOURCE='DELETE_RESOURCE';

const getResourcesSync = (resourceList) =>{
    return {
        type:GET_RESOURCES,
        payload:resourceList
    }
}

export const getResources = () =>{
    return dispatch => {
        var config = {
            headers: { 'Content-Type': 'application/json' },
          };

        axios.get("/resource", {}, config).then
        (res => {
            console.log("...........API call made");
            dispatch(getResourcesSync(res.data));
        }).catch(error =>{
            alert('api call to get resources failed ' + error);
        } 

        ); 
    };
};


const saveResource = (resource) =>{
    return {
        type:ADD_RESOURCE,
        payload:resource
    }
}

export const addResource = (resource) =>{
    return dispatch => {
        var config = {
            headers: { 'Content-Type': 'application/json' },
          };

        axios.post("/resource", resource, config).then
        (res => {
            dispatch(saveResource(res));
        }).catch(error =>{
            alert('add resource failed  ' + error);
        } 

        ); 
    };
};

export const editResource = (id) =>{
    return{
        type:UPDATE_RESOURCE,
        payload:id
    };
};