import * as ResourceActions from './resource_actions';

const initialState={
    resources:[]
}
const resourceReducer = (state=initialState, action)=>{
    switch(action.type)
    {
        
        case ResourceActions.GET_RESOURCES:
        {
            console.log('Resource reducer get all ResourceActions.GET_RESOURCES');
            return  {
                ...state,
                resources:action.payload
            }

        } 
        case ResourceActions.ADD_RESOURCE:
        {
            console.log('this is the add attribute in the redux reducer this');
            console.log('Attribute in redux '+action.payload);
            return  {
                ...state,
                resources:action.payload
            }

        }    

        case ResourceActions.UPDATE_RESOURCE:return {...state}
        case ResourceActions.DELETE_RESOURCE:return {...state}
        default: return state;
    }
}

export default resourceReducer;