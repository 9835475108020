import React, {Component} from 'react';
import axios  from 'axios';
import rootReducer from './store/root_reducer';

const  Context = React.createContext();


export class Provider extends Component{
    state={
        attributes :[],
        resources : [],
        dispatch: action =>{
            this.setState(state => rootReducer(state,action));
        }

    };
    componentDidMount(){
        axios.get("http://jcdir.net/attributeItem").then(res => this.setState({attributes:res.data})); 
        axios.get("http://jcdir.net/resource").then(res => this.setState({resources:res.data})); 
    }
    render(){
        return(
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export  const Consumer = Context.Consumer;
