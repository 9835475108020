import {combineReducers} from 'redux';
import attributeReducer  from './attribute_reducer';
import resourceReducer from './resource_reducer';
import errorReducer  from './error_reducer';

const rootReducer = combineReducers(
    {
        atrReducer:attributeReducer,
        resReducer:resourceReducer,
      //  errReducer:errorReducer
    }
);
export default rootReducer;