import axios  from 'axios';

//attributes
export const GET_ALL_ATTRIBUTES ='GET_ALL_ATTRIBUTES';
export const ADD_ATTRIBUTE='ADD_ATTRIBUTE';
export const EDIT_ATTRIBUTE="EDIT_ATTRIBUTE";
export const UPDATE_ATTRIBUTE='UPDATE_ATTRIBUTE';
export const DELETE_ATTRIBUTE='DELETE_ATTRIBUTE';
export const CLEAR_UPDATES='CLEAR_UPDATES';
export const HANDLE_ERROR='HANDLE_ERROR';
export const HANDLE_DELETE_ERROR='HANDLE_DELETE_ERROR';

//attribute types
export const GET_ATTRIBUTE_TYPES ='GET_ATTRIBUTE_TYPES';


export const clearUpdates = () =>{
    return {
        type:CLEAR_UPDATES
    }
}

const getAttributeSync = (attributeList) =>{
    return {
        type:GET_ALL_ATTRIBUTES,
        payload:attributeList
    }
}
const handleError = (res)=>{
    return{
        type:HANDLE_ERROR,
        payload:res
    };

}
const handleDeleteError = (res)=>{
    return{
        type:HANDLE_DELETE_ERROR,
        payload:res
    };

}
export const getAllAttributes = () =>{
    return dispatch => {
        var config = {
            headers: { 'Content-Type': 'application/json' },
          };

        axios.get("/attributeItem", {}, config).then
        (res => {
            console.log("...........API call made");
            dispatch(getAttributeSync(res.data));
        }).catch(error =>{
           dispatch(handleError(error));
        } 

        ); 
    };
};

const getAttributeTypesSync = (attributeTypes) =>{
    return {
        type:GET_ATTRIBUTE_TYPES,
        payload:attributeTypes
    }
}

export const getAttributeTypes = () =>{
    return dispatch => {
        var config = {
            headers: { 'Content-Type': 'application/json' },
          };

        axios.get("/attribute", {}, config).then
        (res => {
            dispatch(getAttributeTypesSync(res.data));
        }).catch(error =>{
            alert('get attributes types failed failed  ' + error);
        } 

        ); 
    };
};


const addAttributeSync = (attribute) =>{
    return {
        type:ADD_ATTRIBUTE,
        payload:attribute
    }
}

export const addAttribute = (attribute) =>{
    return dispatch => {
        var config = {
            headers: { 'Content-Type': 'application/json' },
          };

        axios.post("/attributeItem", attribute, config).then
        (res => {
            return dispatch(addAttributeSync(res.data));
        }).catch(error =>{
            dispatch(handleError(error));
        } 

        ); 
    };
};


const updateAttributeSync = (attribute) =>{
    return {
        type:UPDATE_ATTRIBUTE,
        payload:attribute
    }
}

export const updateAttribute = (attribute) =>{
    return dispatch => {
        var config = {
            headers: { 'Content-Type': 'application/json' },
          };

        axios.post("/attributeItem/"+attribute.id, attribute, config).then
        (res => {
            return dispatch(updateAttributeSync(res.data));
        }).catch(error =>{
            dispatch(handleError(error));
        } 

        ); 
    };
};

export const deleteAttributeSync = (attribute)=>{
    return {
        type:DELETE_ATTRIBUTE,
        payload:attribute
    }
}

export const deleteAttribute = (attribute) =>{
    return dispatch => {
        var config = {
            headers: { 'Content-Type': 'application/json' },
          };

        axios.delete("/attributeItem/"+attribute.id, attribute, config).then
        (res => {
            return dispatch(deleteAttributeSync(attribute));
        }).catch(error =>{
            dispatch(handleDeleteError("Issue deleting at the backend"));
        } 

        ); 
    };
};



export const editAttribute = (id) =>{
    return{
        type:EDIT_ATTRIBUTE,
        payload:id
    };
};