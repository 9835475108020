import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DeleteAttributeDialog(props) {

  return (
        <Dialog
        open={props.open}
        onClose={props.closeDialogHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete the following?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" >
            Are you sure you want to delete '{props.attributeName}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeDialogHandler} color="primary" autoFocus>
            No changed my mind
          </Button>
          <Button onClick={props.onDeleteHandler} variant="contained" color="secondary"  startIcon={<DeleteIcon />}>
              Delete</Button>
        </DialogActions>
      </Dialog>
  );
}