import React from 'react';
import {useEffect} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
//import {Consumer} from '../context';
import Moment from 'react-moment';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import * as ResourceActions from '../store/resource_actions';

// Generate Order Data

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  chipMargin:{
    margin:theme.spacing(1),
  }
}));

const  Resources= (props) => {
  useEffect(() =>{
    props.getResourceList();
    },[]// pass empty array for second argument if you want useEffect only to run once
  ); 
  const classes = useStyles();
  return (
              <React.Fragment>
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>Resources</Typography>
                <Table size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Last Update</TableCell>
                        {/* <TableCell align="right">Sale Amount</TableCell>  keep for align example*/}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.resourceList.map(row => (
                        <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{  row.attributeItems.map((attribute)  =>(<Chip key={attribute.id} label={attribute.name} className={classes.chipMargin} />)
                        )}</TableCell>
                        <TableCell><Moment format="MM/DD/YYYY hh:mm" date={row.lastUpdate}/></TableCell>
                        {/* <TableCell align="right">{row.amount}</TableCell> */}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                <div className={classes.seeMore}>
                    <Link color="primary" href="#" onClick={preventDefault}>
                    See more orders
                    </Link>
                </div>
              </React.Fragment>
  );
}

const mapStateToProps = state => {
  return{
    resourceList : state.resReducer.resources
  };
};
const mapDispatchToProps = dispatch =>{
  return{
    getResourceList:() =>dispatch(ResourceActions.getResources())
  };
}
export default connect(mapStateToProps,mapDispatchToProps)(Resources);
