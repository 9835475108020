import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import AttributeForm from '../forms/AttributeForm';

export default function AddUpdateAttributeDialog(props) {
    const existingAttribute = props.targetAttribute !=null? props.targetAttribute:{};
    const {id, name, icon, description, attribute, parent, hasChild } = existingAttribute;
  return (
        <Dialog
        open={props.open}
        onClose={props.closeDialogHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
            <AttributeForm
            id={id} 
            name={name} 
            icon={icon} 
            description={description}
            attribute={attribute}
            parent={parent}
            hasChild={hasChild}
            buttonLabel='Update Attribute' 
            handleSubmit={props.onUpdateHandler}/>


        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
  );
}