import React from 'react';
import AttributeForm from './AttributeForm';
import {Consumer} from '../../context';
import * as Actions from '../../store/attribute_actions';
import {connect} from 'react-redux';

class AddAtribute extends React.Component {


    //AttributeForm.handleSubmit() invokes this a passes in attribute and event
    handleAddAttribute(attribute, event) 
    {
      console.log(attribute);
      event.preventDefault();
      //dispatch({type:Actions.ADD_ATTRIBUTE,payload:attribute});
      this.props.onAddAttribute(attribute); 
    }
  
    render() {

        return (
            <Consumer>
                {
                    value=>{
                        //const dispatch = value.dispatch;
                        return (<AttributeForm buttonLabel='Add Attribute' handleSubmit={this.handleAddAttribute.bind(this)}/>); //remember attribute and event are passed into this call by AttributeForm.handleSubmit
                    }
                }
            </Consumer>
        );
      
    }
  }
 //Uncomment once we convert to redux
const mapStateToProps = state => {
    return{
        
    };
}
const mapDispatchToProps = dispatch =>{
    return {
        onAddAttribute: (attribute) => dispatch(Actions.addAttribute(attribute))
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(AddAtribute);


//export default AddAtribute;